import { useCookies } from "react-cookie";

export default function useGroupSwitcher() {
  const [cookie, setCookie] = useCookies(["gf-group"]);
  const groupSlug = cookie["gf-group"];

  function setGroup(slug: string) {
    setCookie("gf-group", slug, { path: "/" });
  }

  return {
    groupSlug,
    setGroup,
  };
}
