// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import nextConfigFn from "next/config";
import { NextIncomingMessage } from "next/dist/server/request-meta";
import { InitialPageProps } from "types/gf-app-context";

import { getServerSideJWT } from "lib/jwt";
import { URI } from "types/web";
import { GroupConfig } from "types/group";

const config = nextConfigFn();

export async function getInitialProps(
  req: NextIncomingMessage | undefined
): Promise<InitialPageProps> {
  const uri = getURI(req);
  const jwt = getServerSideJWT(req);

  const groupSlug = getGroupSlug(req);

  let groupConfig;
  let groupsList: { slug: string; name: string }[] | null = null;
  if (req && groupSlug) {
    let groupsConfig = await config.serverRuntimeConfig.jsonConfig();
    if ((global as any)?.gfState?.groupsConfig) {
      groupsConfig = (global as any).gfState.groupsConfig;
    }
    const groups = groupsConfig.groups as GroupConfig[];
    if (!groups) {
      throw new Error(
        `No groups found in groupsConfig: ${JSON.stringify(groupsConfig)}`
      );
    }
    groupConfig = groups.find((group) => group.slug === groupSlug);
    const { deployEnv } = config.publicRuntimeConfig;
    if (deployEnv === "development") {
      groupsList = groups.map((group) => ({
        slug: group.slug,
        name: group.name,
      }));
    }
  }

  return { jwt, uri, groupSlug, groupConfig, groups: groupsList };
}

export function getGroupSlug(req: NextIncomingMessage | undefined) {
  if (!req) return undefined;
  let groupSlugHeader = req?.headers["gf-group"] || req?.headers["gf-org"];
  if (Array.isArray(groupSlugHeader)) {
    groupSlugHeader = groupSlugHeader[0];
  }

  const defaultGroupSlug = process.env.DEFAULT_ORG;
  let groupSlug;

  const cookiePair = req?.headers.cookie
    ?.split("; ")
    .map((c) => c.split("="))
    .find((c) => c[0] === "gf-group");
  const cookieGroupSlug = cookiePair ? cookiePair[1] : undefined;

  if (groupSlugHeader) {
    console.log(`groupSlug from gf-group header ${groupSlugHeader}`);
    groupSlug = groupSlugHeader;
  } else if (cookieGroupSlug) {
    console.log(`Using group slug from gf-group cookie: ${cookieGroupSlug}`);
    groupSlug = cookieGroupSlug;
  } else {
    console.log(
      `No gf-group header. Using DEFAULT_ORG=${defaultGroupSlug || ""}`
    );
    groupSlug = defaultGroupSlug;
  }

  return groupSlug;
}

export function getURI(req: NextIncomingMessage | undefined): URI {
  if (typeof window !== "undefined") {
    const hostWithPort = window.location.host;
    let { host, port } = splitHostAndPort(hostWithPort);
    const proto = window.location.protocol.replace(":", "");
    port = port || 443;
    return { host, port, proto };
  } else {
    const hostWithPort = getHeaderValue(req, "host");
    if (!hostWithPort) throw new Error("Cannot determine host");
    let { host, port } = splitHostAndPort(hostWithPort);
    let proto = "https";
    if (!port) {
      if (host === "localhost") {
        port = 3002;
      } else {
        port = 443;
      }
    }
    if (port === 3002) {
      proto = "http";
    }

    return { host, port, proto };
  }
}

function splitHostAndPort(hostWithPort: string) {
  const match = hostWithPort.split(":");
  const host = match[0];

  const port = match[1] ? parseInt(match[1]) : null;

  return { host, port };
}

function getHeaderValue(
  req: NextIncomingMessage | undefined,
  key: string
): string | null {
  if (!req) return null;
  key = key.toLowerCase();
  const value = req.headers[key];
  if (Array.isArray(value)) {
    return value[0];
  }
  return value || null;
}
