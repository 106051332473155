import {
  ApolloClient,
  ApolloLink,
  FetchResult,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import JwtStore from "stores/JwtStore";
import { ApiResponse } from "types/api";
import { sessionIdHeaders } from "./api-util";

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key: string, value: any) =>
      key === "__typename" ? undefined : value;
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    );
  }
  return forward(operation).map((data) => {
    return data;
  });
});

export function initializeApolloClient(
  apiBaseUrl: string,
  jwtStore: JwtStore,
  groupSlug: string,
  sessionId: string
) {
  const customFetch = (uri: string, options: any) => {
    const op = options.headers["gql-operation"];
    uri = uri + `?group=${groupSlug}&op=${op}`;
    return fetch(uri, options);
  };

  const httpLink = createHttpLink({
    uri: apiBaseUrl + "/gql",
    fetch: customFetch,
  });

  const headersLink = setContext((arg, opts) => {
    const { headers } = opts;

    // Add:
    // 1. Authorization header
    // 2. GroupFlow client name
    // 3. GraphQL operation name
    const authToken = jwtStore.jwtValue;
    let newHeaders = {
      ...headers,
      Accept: "application/json",
      "gf-client": "gf-web",
      "gql-operation": arg.operationName,
      "gf-group": groupSlug,
      ...sessionIdHeaders(sessionId),
    };

    if (authToken) {
      newHeaders = {
        ...newHeaders,
        Authorization: authToken ? `Bearer ${authToken}` : "",
      };
    }

    return { headers: newHeaders };
  });

  const link = ApolloLink.from([cleanTypeName, headersLink, httpLink]);

  const apollo = new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });

  return apollo;
}

// Convert Apollo FetchResult to ApiResponse
export function apolloMutationResultToApiResponse<T>(
  result: FetchResult<T>,
  dataProp: string
): ApiResponse<T> {
  if (result.errors && result.errors.length > 0) {
    return {
      error: true,
      serverError: false,
      requestErrors: [],
    };
  } else {
    return {
      error: false,
      data: (result.data as any)[dataProp] as T,
    };
  }
}
