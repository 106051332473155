import { marketingCookieName } from "lib/constants";
import { useRouter } from "next/router";
import { useEffect } from "react";

export default function useTrackMarketingActor([cookies, setCookie]: [
  cookies: any,
  setCookie: any
]) {
  if (typeof window === "undefined") {
    return { actorType: null, actorId: null };
  }
  const router = useRouter();
  const cookieConfig = { path: "/", sameSite: true, secure: true };
  const queryMarketingId =
    typeof router.query.mid === "string" ? router.query.mid : null;
  let cookieMarketingId = cookies[marketingCookieName];

  useEffect(() => {
    if (queryMarketingId) {
      setCookie(marketingCookieName, queryMarketingId, cookieConfig);
    }
  }, [queryMarketingId]);

  return { marketingId: queryMarketingId || cookieMarketingId };
}
