import { ReactElement, useEffect, useRef } from "react";
import { RegisterOptions, UseFormReturn } from "react-hook-form";
import {
  FieldCommon,
  Element,
  FieldRow,
  Answer,
  Section,
  TextField,
  RowLabel,
  AnswerFieldData,
  AnswerParams,
  CheckBoxGroup,
  DynaProps,
  OptionItem,
  Question,
  CloudForms,
  QuestionPurpose,
  FormDefinition,
} from "types/field";
import token from "./token";
import ButtonGroupItem from "types/ButtonGroupItem";
import {
  DynamicOtherTextField,
  DynamicOtherTextFieldProps,
} from "components/dynaUI/DynamicOtherTextField";
import dig from "./dig";
import FieldOptionLayout from "components/form/FieldOptionLayout";
import SimpleFieldError from "components/form/SimpleFieldError";

export const fieldTags = [
  "TextField",
  "TextArea",
  "CheckBoxGroup",
  "RadioGroup",
  "SingleCheckBox",
];

export function buildFieldRow(): FieldRow {
  return {
    tagName: "FieldRow",
    id: token(),
    field: buildTextField(),
    label: buildRowLabel(),
    text: null,
  };
}

export function buildTextField(): TextField {
  return {
    tagName: "TextField",
    id: token(),
    defaultValue: null,
    required: false,
  };
}

export function buildRowLabel(): RowLabel {
  return {
    tagName: "RowLabel",
    value: "",
  };
}

export function formDefaultValues(
  elem: Element | Element[],
  values?: { [index: string]: any }
): { [index: string]: any } {
  values = values || {};

  if (Array.isArray(elem)) {
    const children = elem as Element[];
    return children.reduce(
      (values, child) => formDefaultValues(child, values),
      values
    );
  } else {
    const idAndDefault = getIdAndDefault(elem);
    if (idAndDefault) {
      const { id, value } = idAndDefault;
      return { ...values, [id]: value };
    } else {
      return values;
    }
  }
}

function getIdAndDefault(elem: any) {
  if (elem.defaultValue !== undefined && elem.id) {
    return { id: elem.id, value: elem.defaultValue };
  } else {
    return null;
  }
}

export function fieldCommonProps(field: any): FieldCommon {
  return {
    required: [true, false].includes(field.required)
      ? (field.required as boolean)
      : false,
    defaultValue: field.defaultValue,
    id: field.id,
  };
}

export function combineQuestionsAnswers(
  questions: Question[],
  answers: Answer[]
) {
  return questions.map((question) => {
    const answer = answers.find((a) => a.questionId === question.id);
    return { answer, question };
  });
}

export function questionLabelValue(question: Question) {
  const row = question.component as FieldRow;
  return row.label?.value || null;
}

// Copy answers from the API to the hook form values.
export function useSetCustomAnswers(
  hookForm: UseFormReturn<any>,
  sections: Section[] | null | undefined,
  questions: Question[] | null | undefined,
  answers: Answer[] | undefined | null
) {
  useEffect(() => {
    if (sections && answers && questions) {
      setValuesFromSections(hookForm, sections, questions, answers);
    }
  }, [sections, answers]);
}

export function setValuesFromSections(
  hookForm: UseFormReturn<any>,
  sections: Section[],
  allQuestions: Question[],
  answers: Answer[]
) {
  const questions =
    sections &&
    sections.reduce((questions, section) => {
      const sectionQuestions = allQuestions.filter(
        (q) => q.sectionId === section.id
      );
      return sectionQuestions.concat(questions);
    }, [] as Question[]);

  setValuesFromQuestions(hookForm, questions, answers);
}

// Copy answers from the API to the hook form values.
export function useSetCustomAnswers2(
  hookForm: UseFormReturn<any>,
  questions: Question[] | null | undefined,
  answers: Answer[] | undefined | null
) {
  useEffect(() => {
    setValuesFromQuestions(hookForm, questions, answers);
  }, [questions, answers]);
}

export function setValuesFromQuestions(
  hookForm: UseFormReturn<any>,
  questions: Question[] | null | undefined,
  answers: Answer[] | null | undefined
) {
  if (questions && answers) {
    questions.forEach((q) => {
      const answer = answers.find((a) => a.questionId === q.id);
      if (q.component.field) {
        const arrayType = ["CheckBoxGroup", "OrderedSet"].includes(
          q.component.field.tagName
        );
        const stringValue = answer?.arrayValue && answer.arrayValue[0];
        const value = arrayType ? answer?.arrayValue || [] : stringValue;
        const otherValue = answer?.otherValue || null;
        const otherChecked = Boolean(otherValue);
        hookForm.setValue(`answers.${q.id}.value`, value);
        hookForm.setValue(`answers.${q.id}.otherValue`, otherValue);
        hookForm.setValue(`answers.${q.id}.otherChecked`, otherChecked);
        hookForm.setValue(`answers.${q.id}.meta`, answer?.meta || {});
        hookForm.setValue(`answers.${q.id}.visible`, answer?.visible || false);
      }
    });
  }
}

export function questionFieldName(questionId: string) {
  return `answers.${questionId}.value`;
}

export function transformFormParamsForAnswersUpdate(data: any) {
  let params: Partial<any> = filterTemporaryParams(data);

  if (data.answers) {
    params = { ...params, answers: formAnswersToApiParams(data.answers) };
  }

  return params;
}

export function filterTemporaryParams(data: any) {
  const entries = Object.entries(data).filter(
    ([key, _value]) => !key.startsWith("_")
  );
  return entries.reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
}

export function formAnswersToApiParams(answersData: {
  [index: string]: Partial<AnswerFieldData>;
}): Partial<AnswerParams>[] {
  const answersMap = answersData || {};
  const questionIds: string[] = Object.keys(answersData).filter(
    (id) => id !== "_"
  );

  const apiParams = questionIds.map((questionId) => {
    const fieldAnswer = answersMap[questionId];
    let answer: Partial<AnswerParams> = { questionId };

    if (fieldAnswer) {
      let { meta, value, otherValue, otherChecked, visible } = fieldAnswer;
      let arrayValue;
      if (Array.isArray(value)) {
        arrayValue = value;
      } else {
        arrayValue = [value || null];
      }
      arrayValue = arrayValue.filter((v) => v !== null && v !== undefined);
      answer = { ...answer, arrayValue };

      if (meta) {
        answer = { ...answer, meta };
      }

      if (visible !== undefined) {
        visible = (visible as any) === "true" || visible === true;
        answer = { ...answer, visible };
      }

      answer = {
        ...answer,
        otherValue: otherChecked ? otherValue : null,
      };
    }

    return answer;
  });

  return apiParams;
}

interface UseFieldWithOptionsReturn {
  cbOther:
    | {
        index: number;
        content: ReactElement;
      }
    | undefined;
  options: ButtonGroupItem[];
  register: any;
}

export function useFieldWithOptions(
  props: DynaProps
): UseFieldWithOptionsReturn {
  const { fieldRow, hookForm, questionId } = props;
  const field = fieldRow.field as CheckBoxGroup;
  const options: ButtonGroupItem[] = field.items.map((item) => ({
    value: item.id,
    label: item.label,
    required: item.required,
  }));
  const otherItem = field.items.find((item) => item.special === "other");
  const otherCmp = otherItem && field.other;
  const parentRegularProps = buildRegularFieldProps(props);
  const register = parentRegularProps?.register;
  const name = register?.name;
  const valuesRef = useRef<string[]>([]);

  // Get parent field values
  let values: string[] = [];
  if (hookForm) {
    values = hookForm.watch(name) || [];
  }

  useEffect(() => {
    if (otherItem && name && hookForm) {
      const open = values.includes(otherItem.id);
      hookForm.setValue(`answers.${questionId}.otherChecked`, open);
    }
  }, [!sameArrays(values, valuesRef.current)]);

  valuesRef.current = values;

  let showOther = false;
  let cbOther;
  if (hookForm) {
    showOther = hookForm.watch(`answers.${questionId}.otherChecked`) || false;
    if (showOther && otherItem) {
      const otherProps: DynamicOtherTextFieldProps = {
        ...props,
        fieldRow: otherCmp,
        hookForm,
      };
      const index = field.items.indexOf(otherItem);
      const content = <DynamicOtherTextField {...otherProps} />;
      cbOther = { index, content };
    }
  }

  return {
    options,
    cbOther,
    register,
  };
}

function sameArrays(a1: string[], a2: string[]) {
  if (a1.length !== a2.length) return false;
  for (let i = 0; i < a1.length; i++) {
    if (a2[i] !== a1[i]) {
      return false;
    }
  }
  return true;
}

export function buildRegularFieldProps(
  props: DynaProps
): { [index: string]: any } | null {
  let { fieldRow, hookForm, questionId, component, ...fieldProps } = props;
  const { field } = fieldRow;
  if (!field) {
    return null;
  }
  const registerValue = buildRegisterValue(props);
  const id = field.id;
  return { ...fieldProps, register: registerValue, id };
}

export function buildRegisterValue(props: DynaProps): [any, any] | undefined {
  const {
    fieldRow: { field },
    hookForm,
    questionId,
  } = props;
  const register = hookForm?.register;
  if (!register) {
    return undefined;
  }
  const name = questionFieldName(questionId);

  let validate = {} as { [index: string]: (v: any) => boolean };
  if (field.items) {
    validate = {
      itemRequired: (ids: string[]) => itemRequired(ids, field),
    };
  }

  const validationConstraints = validationConstraintsFromField(field);

  let registerProps: RegisterOptions = {
    required: props.disabled ? false : field.required,
    validate,
    ...validationConstraints,
  };

  return register(name, registerProps);
}

export function validationConstraintsFromField(field: any) {
  let validationConstrains = {};

  if (field.maxLength) {
    validationConstrains = {
      ...validationConstrains,
      maxLength: field.maxLength,
    };
  }

  return validationConstrains;
}

function itemRequired(idsOrValue: string[] | boolean, field: any) {
  const ids = idsOrValue as string[];
  const value = idsOrValue as boolean;
  if (value === false) {
    return false;
  }

  const items = field.items as OptionItem[];
  if (items) {
    const requiredItems = items.filter((item) => item.required);
    const requiredIds = requiredItems.map((item) => item.id);
    const missingIds = requiredIds.filter((id) => !ids.includes(id));
    return missingIds.length === 0;
  }
  return true;
}

export function optionError(props: DynaProps, value: any) {
  const { component: field, hookForm, questionId } = props;
  if (!hookForm) return null;
  const {
    formState: { errors },
    watch,
  } = hookForm;

  if (!errors || Object.keys(errors).length === 0) {
    return null;
  }

  const items = field?.items;
  if (!items) return null;

  const requiredItem = items.find(
    (item: OptionItem) => item.required && item.id === value
  );
  if (!requiredItem) return null;

  const error = dig(errors, `answers.${questionId}.value`);
  if (!error) return null;

  const formValue = watch(`answers.${questionId}.value`);
  if (formValue && formValue.includes(value)) {
    return;
  }

  return <FieldOptionLayout label={<SimpleFieldError message="required" />} />;
}

export function getSectionsAndQuestions(
  cloudForms: CloudForms | null,
  purpose: QuestionPurpose,
  opts?: { admin: boolean }
): FormDefinition {
  if (!cloudForms) {
    return {
      sections: [],
      questions: [],
    };
  }

  const key = purpose === "profile" ? "profile" : "signup";
  const sections1 = cloudForms[key].sections;
  const allQuestions1 = cloudForms[key].questions;

  let sections;
  let allQuestions;
  if (opts?.admin) {
    sections = sections1.filter((s) => s.active);
    allQuestions = allQuestions1.filter((q) => q.active);
  } else {
    sections = sections1.filter((s) => s.memberVisible && s.active);
    allQuestions = allQuestions1.filter((q) => q.memberVisible && q.active);
  }

  const sectionIds = sections.map((s) => s.id);
  const questions = allQuestions.filter((q) =>
    sectionIds.includes(q.sectionId ?? "")
  );

  sections = sections.map((section) => {
    // Attach questions to sections
    const sectionQuestions = questions.filter(
      (q) => q.sectionId === section.id
    );
    return { ...section, questions: sectionQuestions };
  });

  return {
    sections,
    questions,
  };
}
