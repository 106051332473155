// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { ApiError } from "types/api";
import Router from "next/router";
import { MemberStatus, Role } from "types/member";
import {
  useJwtStore,
  useMemberJwt,
  useRemoveJwtCookie,
} from "./gf-app-context";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { jwtCookieName } from "./constants";

export function handleExpiredToken(error: ApiError) {
  if (
    error.requestErrors &&
    error.requestErrors[0].title === "Insufficient Privileges"
  ) {
    loginToContinue();
    return true;
  }

  return false;
}

export function loginToContinue() {
  const path = Router.asPath;
  Router.push(`/login?ret=${encodeURIComponent(path)}`);
}

export function memberHasRoleAccess(
  account: { roles: Role[]; status: MemberStatus } | undefined,
  role: Role | Role[]
) {
  if (account?.status !== "ACTIVE") return false;
  if (account.roles.includes("super") && role !== "developer") return true;

  const roles = Array.isArray(role) ? role : [role];
  for (let i = 0; i < roles.length; i++) {
    if (account.roles.includes(roles[i])) {
      return true;
    }
  }
  return false;
}

export function memberCanSeeAdmin(
  account: { roles: Role[]; status: MemberStatus } | undefined
) {
  if (!account || account.status !== "ACTIVE") return false;
  const roles = account.roles.filter((role) => role !== "content");
  return Boolean(roles.length);
}

export function useLoginToContinue() {
  const jwt = useMemberJwt();
  useEffect(() => {
    if (!jwt) {
      loginToContinue();
    }
  });
}

// Returns functions to login and logout a user
export function useLogin() {
  const [_cookies, setCookie] = useCookies();
  const jwtStore = useJwtStore();
  const removeCookie = useRemoveJwtCookie();

  function login(jwt: string) {
    if (!jwtStore) {
      throw new Error("jwtStore is not set");
    }

    setJwtCookie(jwt);
    jwtStore.setJwt(jwt);
  }

  function setJwtCookie(jwt: string) {
    const opts = { maxAge: 3600 * 24 * 30, path: "/" };
    setCookie(jwtCookieName, jwt, opts);
  }

  function logout() {
    removeCookie();
  }

  return { login, logout };
}

export const EMAIL_PATTERN = /^[^@]+@[^@]+\.[^@]+$/;
