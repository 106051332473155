/**
 * Page bootstrap.
 *
 * Sets up ApplicationContext for the page.
 */

import type { AppProps } from "next/app";
import ReactModal from "react-modal";
import { useCookies } from "react-cookie";
import * as Sentry from "@sentry/browser";

import UserStore from "stores/UserStore";
import ChannelStore from "stores/ChannelStore";
import JwtStore from "stores/JwtStore";
import ContentEditStore from "stores/ContentEditStore";
import { getApiBaseUrl, getWebSocketBaseUrl } from "lib/gf-api/api-util";
import AppContextType, {
  InitialPageProps,
  PagesAppSetupReturn,
} from "types/gf-app-context";
import ContentStore from "stores/ContentStore";
import { jwtCookieName, sessionCookieName } from "lib/constants";
import HamburgerStore from "stores/HamburgerStore";
import LoginModalStore from "stores/LoginModalStore";
import token from "lib/token";
import { useEffect } from "react";
import AlertStore from "stores/AlertStore";
import { initializeApolloClient } from "lib/gf-api/apollo";
import StripeStore from "stores/StripeStore";
import { calculateThemeInfo } from "lib/theme";
import { useRouter } from "next/router";
import FormStore from "stores/FormStore";
import { PageLayout } from "components/layout";
import PasswordSetModalStore from "stores/PasswordSetModalStore";
import useTrackMarketingActor from "./useTrackMarketingActor";

type AppProps2 = AppProps & { pageProps: InitialPageProps };

/**
 * Bootstrap the application.
 *
 * This is used by pages/_app.tsx.
 */
export default function useAppBootstrap(
  appProps: AppProps2
): PagesAppSetupReturn {
  const { Component, pageProps } = appProps;
  let { jwt, uri, groupSlug, groupConfig, groups } = pageProps;
  const router = useRouter();
  const path = router.asPath;

  const [cookies, setCookie] = useCookies();
  jwt = jwt || cookies[jwtCookieName];
  const win: any = typeof window === "undefined" ? undefined : window;
  const { sessionId } = useSetSessionId(cookies, setCookie);
  const { marketingId } = useTrackMarketingActor([cookies, setCookie]);

  // pageProps above, seems to only be populated on initial page load
  // After that, they're absent, so we have to re-fetch them from the serialized __NEXT_DATA__ object.
  if (!groupConfig && typeof window !== "undefined") {
    const el = window.document.getElementById("__NEXT_DATA__");
    if (el?.textContent) {
      const data = JSON.parse(el.textContent);
      const pageProps = data.props.pageProps;
      groupSlug = pageProps.groupSlug;
      groupConfig = pageProps.groupConfig;
      uri = pageProps.uri;
      groups = pageProps.groups;
    }
  }

  if (groupConfig) {
    Sentry.setContext("group", {
      slug: groupConfig.slug,
      name: groupConfig.name,
    });
  }

  const apiBaseUrl = groupSlug && uri && getApiBaseUrl(uri);
  const jwtStore = win?.gfJwtStore || (groupSlug && new JwtStore(jwt)) || null;

  const wsBaseUrl = groupSlug && uri && getWebSocketBaseUrl(uri);
  const channelStore =
    win?.gfChannelStore ||
    (wsBaseUrl &&
      jwtStore &&
      groupSlug &&
      new ChannelStore(wsBaseUrl, jwtStore, groupSlug)) ||
    null;
  if (win) {
    win.gfChannelStore = channelStore;
  }
  const apiCall = { baseUrl: apiBaseUrl, groupSlug, jwt };

  if (channelStore && jwtStore?.memberJwt) {
    channelStore.getWebSocket();
    channelStore.loadMemberChannelAndConversations();
  }

  let contentEditStore;
  if (jwt && groupSlug && apiBaseUrl) {
    contentEditStore = new ContentEditStore({
      baseUrl: apiBaseUrl,
      jwt,
      groupSlug,
    });
  }

  const userStore =
    win?.gfUserStore ||
    (jwtStore &&
      apiBaseUrl &&
      groupSlug &&
      new UserStore(apiBaseUrl, jwtStore, groupSlug)) ||
    null;
  if (jwtStore?.validJwt && userStore) {
    userStore.loadUser();
  }

  const stripeStore = win?.gfStripeStore || (groupConfig && new StripeStore());

  let contentStore = win?.gfContentStore || null;
  if (!contentStore && groupConfig) {
    contentStore = new ContentStore({ ...apiCall, groupConfig });
    contentStore.loadNavigationData();
  }

  let formStore = win?.gfFormStore ?? null;
  if (!formStore && groupConfig) {
    formStore = new FormStore({ group: groupConfig });
    formStore.loadData();
  }

  let hamburgerStore = win?.hamburgerStore;
  if (!hamburgerStore) {
    hamburgerStore = new HamburgerStore();
    if (win) {
      win.hamburgerStore = hamburgerStore;
    }
  }

  let loginModalStore = win?.gfLoginModalStore;
  if (!loginModalStore) {
    loginModalStore = new LoginModalStore();
    if (win) {
      win.loginModalStore = loginModalStore;
    }
  }

  let passwordSetModalStore = win?.gfPasswordSetStore;
  if (!passwordSetModalStore) {
    passwordSetModalStore = new PasswordSetModalStore();
    if (win) {
      win.passwordSetModalStore = passwordSetModalStore;
    }
  }

  let alertStore = win?.gfAlertStore;
  if (!alertStore) {
    alertStore = new AlertStore();
    if (win) {
      win.gfAlertStore = alertStore;
    }
  }

  if (typeof window !== "undefined") {
    (window as any).channelStore = channelStore;
    (window as any).gfChannelStore = channelStore;
    (window as any).gfContentStore = contentStore;
    (window as any).gfHamburgerStore = hamburgerStore;
    (window as any).gfLoginModalStore = loginModalStore;
    (window as any).gfPasswordSetModalStore = passwordSetModalStore;
    (window as any).gfUserStore = userStore;
    (window as any).gfJwtStore = jwtStore;
    (window as any).gfStripeStore = stripeStore;
  }

  const apollo =
    (apiBaseUrl &&
      groupSlug &&
      initializeApolloClient(apiBaseUrl, jwtStore, groupSlug, sessionId)) ||
    undefined;

  ReactModal.setAppElement("#__next");

  let getLayout;
  let metaData;
  if ((Component as any).getLayout) {
    getLayout = (Component as any).getLayout;
  } else if (groupSlug) {
    getLayout = (page: React.ReactNode) => <PageLayout>{page}</PageLayout>;
  } else {
    getLayout = (page: React.ReactNode) => page;
  }

  let contentShowControls = true;
  if ((Component as any).metaData) {
    metaData = (Component as any).metaData();
    if (metaData.contentShowControls === false) {
      contentShowControls = false;
    }
  }

  const appContext2: AppContextType = {
    alertStore,
    apollo,
    channelStore,
    contentEditStore,
    contentShowControls,
    contentStore,
    formStore,
    groupConfig,
    groups,
    groupSlug,
    hamburgerStore,
    jwt,
    jwtStore,
    loginModalStore,
    marketingId,
    passwordSetModalStore,
    sessionId,
    stripeStore,
    themeInfo: calculateThemeInfo(path),
    uri,
    userStore,
  };

  return {
    getLayout,
    component: Component as any,
    appContext: appContext2,
  };
}

function useSetSessionId(cookies: any, setCookie: any) {
  if (typeof window === "undefined") {
    return { sessionId: null };
  }

  const cookieConfig = { path: "/", sameSite: true, secure: true };
  let sessionId = cookies[sessionCookieName];
  useEffect(() => {
    if (!sessionId) {
      sessionId = token();
      setCookie(sessionCookieName, sessionId, cookieConfig);
    }
  }, [sessionId]);

  return { sessionId };
}
