import { ApiCall } from "types/api";
import { fetchAndLog, gfApiHeaders } from "./api-util";

export const recordClick = async (
  call: ApiCall,
  clickData: any
): Promise<void> => {
  const endpointUrl = call.baseUrl + "/click";
  fetchAndLog(endpointUrl, {
    headers: gfApiHeaders(call),
    method: "POST",
    body: JSON.stringify(clickData),
  });
};
