// Copyright © 2017 Moxley Data Systems - All Rights Reserved

export const dayAndDateFormat = "EEEE, MMM dd, yyyy";
export const dateFormat = "MMM dd, yyyy";
export const dayAndDate = "EEEE, MMMM dd";
export const dayMonth = "MMMM dd";
export const gmtOffset = "OOO";
export const timeFormat = "h:mm aaa";
export const jwtCookieName = "mp1md_jwt";
export const sessionCookieName = "gfsid";
export const marketingCookieName = "gfmid";
export const defaultUserPhotoUrl =
  "https://mp1md-pub.s3.us-west-2.amazonaws.com/icons/profile.png";
