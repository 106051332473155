// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import React, { ReactElement, useEffect } from "react";
import {
  useHamburgerStore,
  useMember,
  usePageEngine,
} from "lib/gf-app-context";
import { useRouter } from "next/router";
import { observer } from "mobx-react-lite";
import { LayoutConfig } from "types/page";
import PageWrapperForSideCar from "components/layout/PageWrapperForSideCar";
import PageSections from "components/layout/PageSections";
import { GlobalUIs } from "./GlobalUIs";

interface Props {
  children: React.ReactNode;
  layoutConfig?: LayoutConfig;
  title?: string;
}

/**
 * By default, all pages are wrapped in this PageLayout component.
 *
 * This component is called from components/pages-app.tsx, which in turn is called by pages/_app.tsx.
 *
 * To override this behavior, add a `getLayout()` function to the page component, after the component is defined,
 * but before it is exported from the module.
 */
function PageLayout(props: Props): ReactElement {
  const { children } = props;
  const router = useRouter();
  const pageEngine = usePageEngine();
  const hamburgerStore = useHamburgerStore();
  const member = useMember();
  const path = router.asPath.replace(/\?.*/, "");

  useEffect(() => {
    hamburgerStore?.close();
  }, [router?.asPath]);

  if (pageEngine?.type !== "web-components" && pageEngine?.version !== 2) {
    return <div>Unsupported page engine: {pageEngine.type}.</div>;
  }

  // Render new page design if theme and template engine is specified
  return (
    <>
      <PageWrapperForSideCar sideCarShowing={hamburgerStore?.isOpen || false}>
        <PageSections path={path} loggedIn={!!member}>
          {children}
        </PageSections>
      </PageWrapperForSideCar>
      <GlobalUIs />
    </>
  );
}

export default observer(PageLayout);
