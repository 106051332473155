// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { RadioProps } from "components/form/RadioWithLabel";
import {
  AdminMembersListFilterValues,
  Me,
  Member,
  MemberItem,
  MemberRoleOption,
  MemberStatus,
  NotificationMethod,
} from "types/member";
import { SearchFilter } from "types/ui";

export const SubscriptionFormFields = [
  {
    name: "groupAnnouncements",
    label: "Group-wide announcements",
    memberStatuses: ["ACTIVE", "INACTIVE", "NON_MEMBER"],
  },
  {
    name: "memberMessages",
    label: "Notification of a new chat message to you",
    memberStatuses: ["ACTIVE", "INACTIVE"],
  },
  {
    name: "eventAnnouncements",
    label: "Events: New event announcement",
    memberStatuses: ["ACTIVE", "INACTIVE", "NON_MEMBER"],
  },
  {
    name: "eventReminders",
    label: "Events: Weekly 'Last chance' reminder of upcoming events",
    memberStatuses: ["ACTIVE", "INACTIVE", "NON_MEMBER"],
  },
  {
    name: "rsvpConfirmed",
    label: "Events: Registration confirmation receipt",
    memberStatuses: ["ACTIVE", "INACTIVE", "NON_MEMBER"],
  },
  {
    name: "eventComments",
    label:
      "Events: Notification of new messages from an event's discussion thread, for an event you are going to",
    memberStatuses: ["ACTIVE"],
  },
  {
    name: "rsvpReminders",
    label:
      "Events: Reminder of an event tomorrow you have RSVPd 'yes' or 'maybe' to",
    memberStatuses: ["ACTIVE", "INACTIVE", "NON_MEMBER"],
  },
  {
    name: "newRsvps",
    label:
      "Events: Hosts only: Notification of new RSVPs to an event you're hosting",
    memberStatuses: ["ACTIVE"],
  },
];

export const SubscriptionDefaultValues = SubscriptionFormFields.reduce(
  (obj, field) => ({ ...obj, [field.name]: ["email"] as NotificationMethod[] }),
  {} as { [index: string]: NotificationMethod[] }
);

export function memberToMemberItem(member: Member): MemberItem {
  const { profile, ...memberItemValues } = member;

  return {
    ...memberItemValues,
    intro: "",
    location: "",
    name: "",
    title: "",
    photo: null,
    pronouns: [],
    ...profile,
    memberMeetupId: null,
  };
}

export const MemberRoles: MemberRoleOption[] = [
  { value: "content", label: "Content Management" },
  { value: "elections", label: "Elections" },
  { value: "events", label: "Events" },
  { value: "finances", label: "Finances" },
  { value: "group_announcements", label: "Group Announcements" },
  { value: "members", label: "Members Management" },
  { value: "super", label: "Superuser" },
];

export const SpecialFields = [
  { value: "intro", label: "Introduction", purpose: "profile" },
  { value: "location", label: "Location", purpose: "profile" },
  { value: "meetup", label: "Meetup Question", purpose: "profile" },
  { value: "name", label: "Name", purpose: "profile" },
  { value: "photo", label: "Photo", purpose: "profile" },
  { value: "pronouns", label: "Pronouns", purpose: "profile" },
];

export function memberIsActive(member: Me | undefined | null) {
  if (!member) return false;
  const { account } = member;
  return account.status === "ACTIVE";
}

export const MEMBERS_LIST_ADMIN_FILTERS: SearchFilter<
  keyof AdminMembersListFilterValues
>[] = [
  {
    defaultLabel: "Account Status",
    name: "status",
    options: [
      {
        label: "Active Member",
        value: "ACTIVE",
        filterParams: { status: { eq: "ACTIVE" } },
      },
      {
        label: "Inactive Member",
        value: "INACTIVE",
        filterParams: { status: { eq: "INACTIVE" } },
      },
      {
        label: "Banned",
        value: "BANNED",
        filterParams: { status: { eq: "BANNED" } },
      },
      {
        label: "Non-Member",
        value: "NON_MEMBER",
        filterParams: { status: { eq: "NON_MEMBER" } },
      },
    ],
    allowReset: true,
  },
  {
    defaultLabel: "Dues",
    name: "dues",
    options: [
      {
        label: "Dues Exempt",
        value: "dues-exempt",
        filterParams: { duesExempt: { eq: true } },
      },
      {
        label: "Non-Exempt",
        value: "non-exempt",
        filterParams: { duesExempt: { eq: false } },
      },
    ],
    allowReset: true,
  },
  {
    defaultLabel: "Role",
    name: "role",
    options: MemberRoles.map((mr) => ({
      ...mr,
      params: { role: mr.value },
    })),
    allowReset: true,
  },
  {
    defaultLabel: "Sort: Recently Created",
    name: "sort",
    options: [
      { label: "Name", value: "name", filterParams: {} },
      {
        label: "Recently Created",
        value: "recently-created",
        filterParams: {},
      },
    ],
  },
];

interface MemberStatusOption {
  label: string;
  value: MemberStatus;
}

export const MEMBER_STATUS_OPTIONS: (RadioProps & MemberStatusOption)[] = [
  {
    value: "NON_MEMBER",
    label: "Non-member",
  },
  {
    value: "INACTIVE",
    label: "Inactive Member",
  },
  {
    value: "ACTIVE",
    label: "Active Member",
  },
  {
    value: "BANNED",
    label: "Banned",
  },
];

export const MAX_NAME_LENGTH = 40;
