"use client";

// UIs that appear globally in the application, such SideCar menu, login modal, etc.

import LoginModal from "components/login/LoginModal";
import SideCarMenu from "./SideCarMenu";
import PasswordSetPrompt from "components/member/PasswordSetPrompt";
import ContentEditing from "components/contentEditing/ContentEditing";
import GroupSwitcher from "components/dev/GroupSwitcher";
import SupportChat from "components/support/SupportChat";

export function GlobalUIs() {
  return (
    <>
      <SideCarMenu />
      <LoginModal />
      <PasswordSetPrompt />
      <GroupSwitcher />
      <div className="LowerRightControls flex flex-col gap-1 fixed z-20 bottom-4 right-4 md:right-6 m-1 font-montserrat">
        <ContentEditing />
        <SupportChat />
      </div>
    </>
  );
}
