// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { TextInput } from "components/form";
import React, { useEffect, useRef, useState } from "react";
import { useMemberJwt } from "lib/gf-app-context";
import ResultsDropDown from "./event/ResultsDropDown";
import throttled, { useThrottleState } from "lib/throttled";

interface Props {
  childrenAfter?: React.ReactNode;
  focusOnLoad?: boolean;
  inputRef?: React.RefObject<HTMLInputElement | null>;
  items: any[] | null;
  mapResultItem?: (item: any) => React.ReactNode;
  onCancel?: () => void;
  onItemSelected?: (item: any) => void;
  onSearch?: (query: string) => void;
  placeholder?: string;
}

export interface InputState {
  active: boolean;
  query: string;
}

const defaultInputState = {
  active: false,
  query: "",
} as InputState;

const inputRef: React.RefObject<HTMLInputElement | null> = React.createRef();

function useRateLimit() {
  const state = useRef<any>({});

  const doLimit = (fun: any) => {
    const currentTime = new Date().valueOf();
    if (!state.current.date) {
      state.current.date = currentTime;
      fun();
      setTimeout(() => {
        state.current.fun && state.current.fun();
        state.current = {};
      }, 500);
    } else {
      state.current.fun = fun;
    }
  };

  return {
    trigger: (fun: any) => {
      doLimit(fun);
    },
  };
}

export default function ItemSearch(props: Props) {
  const { items } = props;
  const jwt = useMemberJwt();
  const [inputState, setInputState]: [InputState, (value: InputState) => void] =
    useState(defaultInputState);
  const [throttleState, setThrottleState] = useThrottleState();
  let currentId: string | undefined;
  let setCurrentId: (id: string | undefined) => void;
  [currentId, setCurrentId] = useState(currentId);
  let initialSearch: boolean;
  let setInitialSearch: (value: boolean) => void;
  [initialSearch, setInitialSearch] = useState(false as boolean);
  const usingRateLimit = useRateLimit();

  function handleKeyDown(e: KeyboardEvent) {
    if (e.key === "Escape") {
      closeDropdown();
    } else {
      let moveOffset = 0;

      if (e.key === "ArrowDown") {
        moveOffset = 1;
      } else if (e.key === "ArrowUp") {
        moveOffset = -1;
      } else if (e.ctrlKey && e.key === "n") {
        moveOffset = 1;
      } else if (e.ctrlKey && e.key === "p") {
        moveOffset = -1;
      } else if (e.key === "Enter") {
        e.preventDefault();
        selectItem();
      }

      if (moveOffset) {
        if (!items) return;
        e.preventDefault();
        const itemIndex = items.findIndex((v) => v.id === currentId);
        const newCurrentItem = items[itemIndex + moveOffset];
        if (newCurrentItem) {
          setCurrentId(newCurrentItem.id);
        }
      }
    }
  }

  function selectItem(item?: any) {
    if (!item) {
      if (!items) return;
      item = items.find((v) => v.id === currentId);
      if (!item) return;
    }

    props.onItemSelected && props.onItemSelected(item);
    setInputState({ ...inputState, active: false, query: "" });
    props.inputRef?.current?.blur();
  }

  function closeDropdown(updatedInputState?: Partial<InputState>) {
    if (!items) return;
    setCurrentId(undefined);
    setInputState({ ...inputState, ...updatedInputState, active: false });
  }

  function onInputFocus() {
    setInputState({ active: true, query: "" });
    search("");
  }

  function onInputBlur() {
    closeDropdown();
    const { onCancel } = props;
    onCancel && onCancel();
  }

  function search(query: string) {
    const { onSearch } = props;
    if (!jwt || !onSearch) return;

    setCurrentId(undefined);
    usingRateLimit.trigger(() => onSearch(query));
  }

  function componentRef() {
    const ref = props.inputRef || inputRef;
    if (ref?.current && props.focusOnLoad) {
      ref.current.focus();
    }
  }

  const onItemSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { value } = e.target;
    const query = value;
    setInputState({ ...inputState, active: true, query });

    const callback = () => search(query);

    throttled(callback, throttleState, setThrottleState);
  };

  useEffect(() => {
    if (!initialSearch) {
      setInitialSearch(true);
    }
    if (
      items &&
      items[0] &&
      (!currentId || !items.find((item) => item.id === currentId))
    ) {
      setCurrentId(items[0].id);
    }
  });

  return (
    <div className="relative" ref={componentRef}>
      <TextInput
        onChange={onItemSearch}
        value={inputState.query}
        onKeyDown={handleKeyDown}
        onBlur={onInputBlur}
        onFocus={onInputFocus}
        placeholder={props.placeholder || "Search…"}
        inputRef={props.inputRef || inputRef}
        autoComplete="fuck-off-chrome"
      />
      {inputState.active && items && (
        <ResultsDropDown
          items={items}
          currentId={currentId}
          onItemClick={selectItem}
          mapItem={props.mapResultItem}
          childrenAfter={props.childrenAfter}
        />
      )}
    </div>
  );
}
