import Flash from "components/Flash";
import { MutationInfo } from "types/ash";

interface Props<T> {
  mutationInfo?: MutationInfo<T>;
}

export default function AshMutationErrors<T>({ mutationInfo }: Props<T>) {
  if (!mutationInfo) return null;
  const { serverError, errors } = mutationInfo;

  const formatMessage = (error: Record<string, any>) => {
    let message = error.message as string;
    if (!message && error.code) {
      const code = error.code.replace("_", " ");
      message = code;
    }

    if (Array.isArray(error.fields) && error.fields.length) {
      const field = error.fields.at(-1);
      message = `${field}: ${message}`;
    }
    return message;
  };

  return (
    <>
      {serverError && <Flash type="warning">{serverError.message}</Flash>}
      {errors &&
        errors.map((error: { code?: string; message: string }, index) => (
          <Flash type="warning" key={index}>
            Error: {formatMessage(error)}
          </Flash>
        ))}
    </>
  );
}
