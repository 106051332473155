import { recordClick } from "lib/gf-api/clickstream-api";
import {
  useApiCallParams,
  useAppContext,
  useJwtStore,
} from "lib/gf-app-context";
import { SyntheticEvent, useCallback, useEffect } from "react";

function Clickstream() {
  const { marketingId, sessionId } = useAppContext();
  const jwtStore = useJwtStore();
  const apiCall = useApiCallParams();

  const recordEvent = (type: string, target?: HTMLElement) => {
    const timestamp = new Date().toISOString();
    const url = window.location.href;
    const sub = jwtStore.validClaims?.sub;
    const aud = jwtStore.validClaims?.aud;
    const tagName = target?.tagName ?? null;
    let textContent = target?.textContent?.trim() ?? null;
    if (tagName === "TEXTAREA") {
      textContent = "[redacted]";
    }

    // Construct the clickstream data
    const clickData = {
      marketingId,
      sessionId,
      jwtClaims: sub ? { sub, aud } : null,
      timestamp,
      type,
      url,
      target:
        (target && {
          id: target.id,
          className: target.className,
          tagName: target.tagName,
          textContent,
        }) ||
        null,
    };

    recordClick(apiCall, clickData);
  };

  const handleClick = useCallback(
    (event: SyntheticEvent<HTMLElement>): any => {
      // Gather relevant data from the event object
      const type = event.type;
      const target = event.target as HTMLElement;
      recordEvent(type, target);
    },
    [jwtStore.validClaims, sessionId]
  );

  useEffect(() => {
    // Attach the event listener to the document body
    document.body.addEventListener("click", handleClick as any);

    // Cleanup the event listener on component unmount
    return () => {
      document.body.removeEventListener("click", handleClick as any);
    };
  }, [jwtStore.validClaims, sessionId]);

  useEffect(() => {
    // Record page view
    recordEvent("page");
  });

  return null;
}

export default Clickstream;
