// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { gql } from "@apollo/client";
import {
  ApiCall,
  ApiResponse,
  GraphQlOneRequest,
  GraphQlRequest,
} from "types/api";
import { AnswerParams, Section } from "types/field";
import {
  DuesNotice,
  ElectionNotice,
  EmbeddedListMembersParams,
  ListMembersResponse,
  Member,
  Member2,
  MemberNotices,
  ProfileParams,
  ProfileWithMember,
  Profile2,
} from "types/member";
import { parseDate } from "./apiDecode";
import { answerFields } from "./field-api";
import { ERROR_OBJECT, graphqlMultiCall, graphqlSingleCall } from "./gql";
import { photoFields } from "./photo-api";

export interface ProfileAndSections {
  profile: ProfileWithMember;
  sections: Section[];
}

const memberItemFields = ["id", "joinedGroupOn", "leftGroupOn"];

const profileFields = [
  `answers { ${answerFields.join(", ")} }`,
  `member { ${memberItemFields.join(", ")} }`,
  "memberId",
  "memberType",
  "location",
  "name",
  "intro",
  `photo { ${photoFields.join(",")} }`,
  "pronouns",
  "title",
];

const profileItemFields = [
  `answers { ${answerFields.join(", ")} }`,
  "memberId",
  "memberType",
  "location",
  "name",
  "intro",
  `photo { ${photoFields.join(",")} }`,
  "pronouns",
  "title",
];

export const memberFields = [
  "id",
  "email",
  "emailsEnabled",
  "joinedGroupOn",
  "leftGroupOn",
  `profile { ${profileItemFields.join(" ")} }`,
  "receivesEmails",
  "status",
  "title",
];

const paginationFields = [
  "pageNumber",
  "pageSize",
  "totalPages",
  "totalEntries",
];

// This is used in small, lookup-style UI components
// This will be deprecated once LIST_MEMBERS2 supports the same input params.
export async function listMembers(
  call: ApiCall,
  params?: Partial<EmbeddedListMembersParams>
): Promise<ApiResponse<ListMembersResponse>> {
  const req: GraphQlOneRequest = {
    call,
    opName: "ListMembers",
    opType: "query",
    query: {
      variables: { input: params },
      argTypes: { input: "MembersInput" },
      qName: "members",
      decoder: (data) => ({ ...data, entries: data.entries.map(decodeMember) }),
      returnNames: [
        `entries { ${memberFields.join("\n")} }`,
        `pagination { ${paginationFields.join(" ")} }`,
      ],
    },
  };
  return graphqlSingleCall<any>(req);
}

// Deprecated. Use GET_MEMBER2 instead.
export async function getMember(
  call: ApiCall,
  id: string
): Promise<ApiResponse<Member>> {
  const req: GraphQlOneRequest = {
    call,
    opName: "GetMember",
    opType: "query",
    query: {
      argTypes: { id: "ID" },
      variables: { id },
      decoder: decodeMember,
      qName: "member",
      returnNames: memberFields,
    },
  };
  return graphqlSingleCall<Member>(req);
}

function decodeMember(data: any): Member {
  let { profile } = data;
  let member = decodeProfileMember(data);
  member = { ...member, profile: profile || null };
  return member;
}

// Connect a member account to an external service
export async function connectAccount(
  call: ApiCall,
  {
    oauthCode,
    provider,
  }: {
    oauthCode?: string;
    provider?: string;
  }
): Promise<ApiResponse<any>> {
  const args = { oauthCode, provider };

  const req: GraphQlOneRequest = {
    call,
    opName: "ConnectAccount",
    opType: "mutation",
    query: {
      qName: "connectAccount",
      args,
      returnNames: ["id", "externalId"],
    },
  };
  return graphqlSingleCall<any>(req);
}

// Disconnect a member account from an external service
export async function disconnectAccount(
  call: ApiCall,
  provider?: string
): Promise<ApiResponse<any>> {
  const args = { provider };

  const req: GraphQlOneRequest = {
    call,
    opName: "DisconnectAccount",
    opType: "mutation",
    query: {
      qName: "disconnectAccount",
      args,
      returnNames: ["id", "externalId"],
    },
  };
  return graphqlSingleCall<any>(req);
}

function decodeProfileWithMember(data: any): ProfileWithMember {
  let { member } = data;
  member = decodeProfileMember(member);
  return { ...data, member };
}

function decodeProfileMember(data: any) {
  let { joinedGroupOn, leftGroupOn } = data;
  joinedGroupOn = parseDate(joinedGroupOn);
  leftGroupOn = parseDate(leftGroupOn);
  return { ...data, joinedGroupOn, leftGroupOn };
}

export async function updateProfile(
  call: ApiCall,
  params: Partial<ProfileParams>
): Promise<ApiResponse<Profile2>> {
  let answers: any | undefined = params.answers;
  if (answers) {
    answers = answers.map((ans: Partial<AnswerParams>) => {
      if (ans.meta) {
        return { ...ans, meta: JSON.stringify(ans.meta) };
      } else {
        return ans;
      }
    });
    params = { ...params, answers };
  }

  const updateArgTypes = {
    params: "ProfileParams!",
  };
  const argTypes = updateArgTypes;

  const req: GraphQlRequest = {
    call,
    opName: "UpdateProfile",
    opType: "mutation",
    argTypes,
    variables: { params },
    queries: [
      {
        argTypes: updateArgTypes,
        qName: "updateProfile",
        returnNames: profileFields,
        decoder: decodeProfileWithMember,
      },
    ],
  };
  let result = await graphqlMultiCall<any>(req);
  if (result.error) {
    return result;
  } else {
    result = { ...result, data: result.data.updateProfile };
    return result;
  }
}

export async function getMemberToken(
  call: ApiCall,
  id: string
): Promise<ApiResponse<{ token: string }>> {
  const argTypes = { id: "ID" };

  const req: GraphQlOneRequest = {
    call,
    opName: "GetMemberToken",
    opType: "query",
    query: {
      argTypes,
      variables: { id },
      qName: "memberToken",
      returnNames: ["token"],
    },
  };
  return graphqlSingleCall<{ token: string }>(req);
}

const answerCompletionFields = ["id", "completed", "visible"];
const electionNoticeFields = ["id", "openedAt", "closedAt", "votedAt"];
const duesNoticeFields = [
  "autoRenews",
  "membershipEndsOn",
  "trialEndsOn",
  "duesExempt",
];
const memberNoticesFields = [
  `dues { ${duesNoticeFields.join(" ")} }`,
  `election { ${electionNoticeFields.join(" ")} }`,
  `answers { ${answerCompletionFields.join(" ")} }`,
];

export async function getMemberNotices(
  call: ApiCall
): Promise<ApiResponse<MemberNotices>> {
  const req: GraphQlOneRequest = {
    call,
    opName: "GetMemberNotices",
    opType: "query",
    query: {
      decoder: decodeMemberNotices,
      qName: "memberNotices",
      returnNames: memberNoticesFields,
    },
  };
  return graphqlSingleCall<MemberNotices>(req);
}

function decodeMemberNotices(data: any) {
  const { election, dues } = data;

  return {
    ...data,
    election: decodeElectionNotice(election),
    dues: decodeDuesNotice(dues),
  };
}

function decodeElectionNotice(data: any) {
  if (!data) return data;
  const { openedAt, closedAt, votedAt } = data;

  return {
    ...data,
    openedAt: parseDate(openedAt),
    closedAt: parseDate(closedAt),
    votedAt: parseDate(votedAt),
  } as ElectionNotice;
}

function decodeDuesNotice(data: any) {
  if (!data) return data;
  const { membershipEndsOn, trialEndsOn } = data;

  return {
    ...data,
    membershipEndsOn: parseDate(membershipEndsOn),
    trialEndsOn: parseDate(trialEndsOn),
  } as DuesNotice;
}

export const ADMIN_MEMBER_FIELDS = `
  duesExempt
  email
  emailsEnabled
  id
  joinedGroupOn
  leftGroupOn
  meetupMemberId
  membershipEndsOn
  membershipStartsOn
  memberTags {
    id
    tag {
      id
      tagGroupId
      value
    }
  }
  memberType
  name
  notes(sort: { field: INSERTED_AT, order: DESC }) {
    author {
      id
      name
    }
    body
    id
    insertedAt
  }
  phoneNumber
  profile {
    answers {
      arrayValue
      id
      meta
      otherValue
      questionId
      visible
    }
    location
    photo {
      id
      url
      thumbUrl
    }
    pronounItems {
      label
      other
    }
  }
  roles
  signups(sort: { field: APPLIED_AT, order: DESC }, limit: 1) {
    id
    joinedAt
    approvedOrDeclinedAt
    appliedAt
  }
  source
  status
  title
  trial
  trialEndsOn
`;

export const MINIMAL_MEMBER_FIELDS2 = gql`
  fragment MinimalMemberFields on MinimalMember {
    id
    joinedGroupOn
    memberType
    name
    profile {
      location
      photo {
        id
        url
        thumbUrl
      }
      pronounItems {
        label
        other
      }
    }
    status
    title
  }
`;

export const ADMIN_LIST_MEMBERS = gql`
  query AdminListMembers2(
    $filter: Member2FilterInput
    $sort: [Member2SortInput]
    $role: String
    $savedReportId: String
    $search: String
    $tagIds: [String!]
  ) {
    adminListMembers2(
      filter: $filter
      sort: $sort
      role: $role
      savedReportId: $savedReportId
      search: $search
      tagIds: $tagIds
    ) {
      duesExempt
      email
      id
      joinedGroupOn
      memberTags {
        id
        tag {
          id
          tagGroupId
          value
        }
      }
      memberType
      name
      profile {
        location
        photo {
          id
          url
          thumbUrl
        }
        pronounItems {
          label
          other
        }
      }
      roles
      signups(sort: [{ field: APPLIED_AT, order: DESC }], limit: 1) {
        id
        insertedAt
        submittedAt
      }
      source
      status
      title
      trial
    }
  }
`;

export const LIST_MEMBERS_FIELDS = `
        id
        joinedGroupOn
        memberType
        name
        profile {
          location
          photo {
            id
            url
            thumbUrl
          }
          pronounItems {
            label
            other
          }
        }
        memberTags {
          id
          tag {
            id
            tagGroupId
            value
          }
        }
        status
        title
        trial
`;

export const LIST_MEMBERS = gql`
  query ListMembers2(
    $filter: Member2FilterInput
    $sort: [Member2SortInput]
    $search: String
    $offset: Int
    $limit: Int
    $tagIds: [String!]
    $skipMemberIds: [String!]
  ) {
    listMembers2(
      filter: $filter
      sort: $sort
      search: $search
      offset: $offset
      limit: $limit
      tagIds: $tagIds
      skipMemberIds: $skipMemberIds
    ) {
      results {
        ${LIST_MEMBERS_FIELDS}
      }
      count
      hasNextPage
    }
  }
`;

export const ADMIN_GET_MEMBER = gql`
  query AdminGetMember($id: ID!) {
    adminGetMember(id: $id) {
      ${ADMIN_MEMBER_FIELDS}
    }
  }
`;

export const CREATE_USER_ACCOUNT = gql`
  mutation CreateMember($input: CreateMember2Input!) {
    createMember2(input: $input) {
      result {
        ${ADMIN_MEMBER_FIELDS}
      }
      ${ERROR_OBJECT}
    }
  }
`;

export const UPDATE_MEMBER_ACCOUNT = gql`
  mutation UpdateMember($id: ID!, $input: UpdateMember2Input!) {
    updateMember2(id: $id, input: $input) {
      result {
        ${ADMIN_MEMBER_FIELDS}
      }
      ${ERROR_OBJECT}
    }
  }
`;

export const GET_MEMBER2 = gql`
  query GetMember2($id: ID!) {
    getMember2(id: $id) {
      id
      joinedGroupOn
      memberTags {
        tag {
          id
          tagGroupId
          value
        }
      }
      memberType
      name
      profile {
        answers(filter: { visible: { eq: true } }) {
          arrayValue
          id
          meta
          otherValue
          questionId
          visible
        }
        intro
        location
        photo {
          id
          url
          thumbUrl
        }
        pronounItems {
          label
          other
        }
      }
      status
      title
    }
  }
`;

export const UPDATE_MEMBER = gql`
  mutation UpdateMember2($id: ID!, $input: UpdateMember2Input) {
    updateMember2(id: $id, input: $input) {
      result {
        ${ADMIN_MEMBER_FIELDS}
      }
      ${ERROR_OBJECT}
    }
  }
`;

const SELF_MEMBER_FIELDS = `
      duesExempt
      duesVariantId
      email
      emailsEnabled
      id
      joinedGroupOn
      membershipPlanId
      memberTags {
        tag {
          id
          tagGroupId
          value
        }
      }
      memberType
      name
      phoneNumber
      profile {
        answers {
          arrayValue
          id
          meta
          otherValue
          questionId
          visible
        }
        intro
        location
        photo {
          id
          url
          thumbUrl
        }
        pronounItems {
          label
          other
        }
      }
      roles
      status
      title
`;

export const GET_SELF_MEMBER = gql`
  query GetSelfMember {
    getSelfMember {
      ${SELF_MEMBER_FIELDS}
    }
  }
`;

export const UPDATE_SELF_MEMBER2 = gql`
  mutation UpdateSelfMember2($input: UpdateSelfMember2Input) {
    updateSelfMember2(input: $input) {
      result {
        ${SELF_MEMBER_FIELDS}
      }
      ${ERROR_OBJECT}
    }
  }
`;

export function decodeMember2(member: Member2): Member2 {
  if (member.profile) {
    let answers = member.profile.answers.map((answer) => ({
      ...answer,
      meta: answer.meta ? JSON.parse(answer.meta as any) : {},
    }));
    answers = answers || [];
    member = { ...member, profile: { ...member.profile, answers } };
  }

  return {
    ...member,
    joinedGroupOn: parseDate(member.joinedGroupOn as any),
    leftGroupOn: parseDate(member.leftGroupOn as any),
    membershipStartsOn: parseDate(member.membershipStartsOn as any),
    membershipEndsOn: parseDate(member.membershipEndsOn as any),
  };
}

export const SIGNUP_WITH_AUTH = gql`
  mutation SignupWithAuth($input: SignupWithAuthInput!) {
    signupWithAuth(input: $input) {
      ok
    }
  }
`;

export const VERIFY_MEMBER_AUTH_CODE = gql`
  mutation VerifyMemberAuthCode($input: VerifyMemberAuthCodeInput) {
    verifyMemberAuthCode(input: $input) {
      result {
        accountConfirmed
        id
        name
        email
        status
      }
      metadata {
        accessToken
      }
      ${ERROR_OBJECT}
    }
  }
`;

export const CONFIRM_ACCOUNT = gql`
  mutation ConfirmAccount($input: ConfirmAccountInput) {
    confirmAccount(input: $input) {
      result {
        id
      }
      ${ERROR_OBJECT}
    }
  }
`;
